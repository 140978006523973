import React, { useState } from "react";
import "./Review.css";

const Review = () => {
  // Example submission for review
  const submission = {
    title: "Network And Engage",
    abstract: "Abstract for the Network And Engage submission",
    department: "Computer Science",
    email: "mohan@gmail.com",
    presenterName: "Mohan Kumar",
    feedback: "",
    score: "",
  };

  // State to manage form inputs
  const [reviewFeedback, setReviewFeedback] = useState(submission.feedback);
  const [score, setScore] = useState(submission.score);

  const handleSubmitReview = (e) => {
    e.preventDefault();
    console.log("Review submitted:", { score, reviewFeedback });
  };

  return (
    <>
      <h2>Review Interface</h2>
      <div className="CMSR-review-container">
        <div className="CMSR-submission-details">
          <h4>Submission Details</h4>

          <div className="CMSR-form-row">
            <label className="CMSR-label">Presenter Name:</label>
            <p className="CMSR-static-field">{submission.presenterName}</p>
          </div>

          <div className="CMSR-form-row">
            <label className="CMSR-label">Email:</label>
            <p className="CMSR-static-field">{submission.email}</p>
          </div>

          <div className="CMSR-form-row">
            <label className="CMSR-label">Department:</label>
            <p className="CMSR-static-field">{submission.department}</p>
          </div>

          <div className="CMSR-form-row">
            <label className="CMSR-label">Title:</label>
            <p className="CMSR-static-field">{submission.title}</p>
          </div>

          <div className="CMSR-form-row">
            <label className="CMSR-label">Abstract:</label>
            <textarea
              className="CMSR-feedback-field"
              value={submission.abstract}
              readOnly
            />
          </div>
        </div>

        <form onSubmit={handleSubmitReview}>
          <div className="CMSR-form-row">
            <label className="CMSR-label">Score:</label>
            <input
              type="number"
              className="CMSR-input-field"
              value={score}
              min="1"
              max="10"
              onChange={(e) => setScore(e.target.value)}
              required
            />
          </div>

          <div className="CMSR-form-row">
            <label className="CMSR-label">Review Feedback:</label>
            <textarea
              className="CMSR-feedback-field"
              value={reviewFeedback}
              onChange={(e) => setReviewFeedback(e.target.value)}
              required
            />
          </div>

          <div className="CMSR-button-container">
            <button type="submit" className="CMSR-save-btn">
              Submit Review
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Review;

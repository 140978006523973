import React, { useState } from "react";
import './auth.css';
import { useNavigate } from "react-router-dom";

const AuthPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const navigate = useNavigate()

  const toggleAuthMode = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div className="container-fluid vh-100 d-flex">
      {/* Left Side - Image */}
      <div className="col-md-6 image-side">
        <img src="/register.jpg" alt="login" style={{height:"100%",width:"100%"}} />
      </div>

      {/* Right Side - Form */}
      <div className="col-md-6 form-side d-flex align-items-center justify-content-center">
        <div className="form-container">
          <h2 className="form-title">{isLogin ? "Login" : "Sign Up"}</h2>

          <form>
            {/* Username Field (Only for Sign Up) */}
            {!isLogin && (
              <div className="mb-3">
                <label htmlFor="username" className="form-label">Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="Enter username"
                  required
                />
              </div>
            )}

            {/* Email Field */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email"
                required
              />
            </div>

            {/* Password Field */}
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Enter password"
                required
              />
            </div>

            {/* Confirm Password (Only for Sign Up) */}
            {!isLogin && (
              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  placeholder="Confirm password"
                  required
                />
              </div>
            )}

            <button type="submit" className="btn btn-primary w-100" onClick={()=>navigate("/dashboard")}>
              {isLogin ? "Login" : "Sign Up"}
            </button>
          </form>

          {/* Toggle between Login and Sign Up */}
          <p className="text-center mt-3">
            {isLogin ? "Don't have an account?" : "Already have an account?"}{" "}
            <button className="btn btn-link" onClick={toggleAuthMode}>
              {isLogin ? "Sign Up" : "Login"}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
import React from 'react';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';// Settings icon
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const nav = useNavigate()
  return (
    <header className="header">
      <div className="profile-section">
        <span className="profile-name">John Doe</span> 
        <img src="/profile.jpg" alt="Profile" className="profile-image" />
        <FontAwesomeIcon icon={faCog} className="settings-icon" onClick={()=>nav("/profile-settings")}/>
      </div>
    </header>
  );
};

export default Header;

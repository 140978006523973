import React, { useState } from "react";
import "./SubmissionManagement.css";

const SubmissionManagement = () => {
  // Sample submission data
  const submissions = [
    {
      id: 1,
      title: "Network And Engage",
      abstract: "Abstract for Network And Engage",
      status: "Under Review",
      feedback: "Needs more details on methodology.",
      decision: "Pending",
    },
    {
      id: 2,
      title: "Conference Impact",
      abstract: "Impact of Virtual Conferences",
      status: "Accepted",
      feedback: "Great work! Approved for publication.",
      decision: "Accepted",
    },
    {
      id: 3,
      title: "Technical Sessions",
      abstract: "Engaging Technical Sessions for audiences",
      status: "Rejected",
      feedback: "Insufficient data for conclusions.",
      decision: "Rejected",
    },
  ];

  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [action, setAction] = useState("Update");
  const [status, setStatus] = useState("");

  // Handle submission selection
  const handleRowClick = (submission) => {
    setSelectedSubmission(submission);
    setStatus(submission.status);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSubmitAction = (e) => {
    e.preventDefault();
    if (selectedSubmission) {
      console.log("Action:", action);
      console.log("Status Updated To:", status);
    }
  };

  return (
    <>
      <h2>Submission Management</h2>
      <div className="CMS-SM-submission-container">
        <table className="CMS-SM-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Status</th>
              <th>Decision</th>
            </tr>
          </thead>
          <tbody>
            {submissions.map((submission) => (
              <tr
                key={submission.id}
                onClick={() => handleRowClick(submission)}
              >
                <td>{submission.title}</td>
                <td>{submission.status}</td>
                <td>{submission.decision}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {selectedSubmission && (
          <div className="CMS-SM-submission-details">
            <h3>Submission Details</h3>
            <div className="CMS-SM-form-row">
              <label className="CMS-SM-label">Title:</label>
              <p className="CMS-SM-static-field">{selectedSubmission.title}</p>
            </div>

            <div className="CMS-SM-form-row">
              <label className="CMS-SM-label">Abstract:</label>
              <p className="CMS-SM-static-field">{selectedSubmission.abstract}</p>
            </div>

            <div className="CMS-SM-form-row">
              <label className="CMS-SM-label">Feedback:</label>
              <textarea
                className="CMS-SM-feedback-field"
                value={selectedSubmission.feedback}
                readOnly
              />
            </div>

            <div className="CMS-SM-form-row">
              <label className="CMS-SM-label">Status:</label>
              <select
                className="CMS-SM-select-status"
                value={status}
                onChange={handleStatusChange}
              >
                <option value="Under Review">Under Review</option>
                <option value="Accepted">Accepted</option>
                <option value="Rejected">Rejected</option>
                <option value="Pending">Pending</option>
              </select>
            </div>

            <div className="CMS-SM-form-row-actions">
              <label className="CMS-SM-label">Actions for Submission:</label>
              <div className="CMS-SM-degree-options">
                <label>
                  <input
                    type="radio"
                    name="action"
                    value="Update"
                    checked={action === "Update"}
                    onChange={() => setAction("Update")}
                  />
                  Update
                </label>
                <label>
                  <input
                    type="radio"
                    name="action"
                    value="Withdraw"
                    checked={action === "Withdraw"}
                    onChange={() => setAction("Withdraw")}
                  />
                  Withdraw
                </label>
              </div>
            </div>

            <div className="CMS-SM-button-container">
              <button
                type="submit"
                className="CMS-SM-save-btn"
                onClick={handleSubmitAction}
              >
                Submit Action
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SubmissionManagement;

import React from "react";
import "./CallForPaper.css";

const CallForPaper = () => {
  return (
    <>
      <h2>Call For Papers</h2>
      <div className="CMS-papers-submission-container">
        <div className="CMS-papers-deadline-section">
          <label>Submission deadlines</label>
          <p>
            24-08-2023 <span className="CMS-papers-days-left">02 days left</span>
          </p>
        </div>

        <h4>Abstract Submission Form</h4>

        <div className="CMS-papers-form-container">
          <div className="CMS-papers-form-row">
            <input
              type="text"
              placeholder="Presenter Name"
              className="CMS-papers-input-field"
            />
            <input
              type="text"
              placeholder="Last Name"
              className="CMS-papers-input-field"
            />
          </div>

          <div className="CMS-papers-form-row CMS-degree-program">
            <label className="CMS-papers-degree-label">Degree program</label>
            <div className="CMS-papers-degree-options">
              <label>
                <input type="radio" name="degree" value="B.Tech" /> B.Tech
              </label>
              <label>
                <input type="radio" name="degree" value="M.A" /> M.A
              </label>
              <label>
                <input type="radio" name="degree" value="Degree" /> Degree
              </label>
            </div>
          </div>

          <div className="CMS-papers-form-row">
            <select className="CMS-papers-input-field">
              <option value="">Select Department</option>
              <option value="CS">Computer Science</option>
              <option value="ECE">Electronics & Comm.</option>
              <option value="MECH">Mechanical Engineering</option>
            </select>
          </div>

          <div className="CMS-papers-form-row">
            <input
              type="email"
              placeholder="Email"
              className="CMS-papers-input-field"
            />
            <input
              type="text"
              placeholder="Title of your paper"
              className="CMS-papers-input-field"
            />
          </div>

          <div className="CMS-papers-form-row">
            <textarea
              placeholder="Summary of your paper"
              className="CMS-papers-input-field CMS-textarea"
            ></textarea>
          </div>

          <div className="CMS-papers-form-row">
            <textarea
              placeholder="Full Abstract"
              className="CMS-papers-input-field CMS-textarea"
            ></textarea>
          </div>

          <div className="CMS-papers-form-row CMS-upload-section">
            <h4>Upload</h4>
            <div className="CMS-papers-upload-box">
              <p>
                {" "}
                choose file to upload your files. All pdf, doc, csv, xlsx types
                are supported
              </p>
            </div>
          </div>

          <div className="CMS-papers-button-container">
            <button className="CMS-papers-upload-btn">
              Upload
              <input type="file" />
            </button>
          </div>
        </div>

        <div className="CMS-papers-guidelines-section">
          <h3>Guidelines for submitting</h3>
          <ul>
            <li>Check the journal's guidelines for the correct format...</li>
            <li>Include an abstract, introduction, body, conclusion...</li>
            <li>
              Figures and tables should be attractive and clearly visible...
            </li>
          </ul>
        </div>

        <div className="CMS-papers-faq-section">
          <h3>FAQs</h3>
          <p>How to submit the document?</p>
          <p>Guidelines for the maximum length of the paper.</p>
        </div>
      </div>
    </>
  );
};

export default CallForPaper;

import React from "react";
import "./CareerDevelopment.css";

const CareerDevelopment = () => {
  const articles = [
    {
      id: 1,
      title: "Masterclass in Design Thinking, Innovation & Creativity",
      image: "/design.jpeg",
      author: "Ana Kursova",
      buttonText: "Learn More",
    },
    {
      id: 2,
      title: "Meet the Professional Mentor",
      image: "/mentor.jpg",
      author: "Alexander Bastian",
      buttonText: "Learn More",
    },
    {
      id: 3,
      title: "Masterclass in Innovation",
      image: "/innovation.jpg",
      author: "John Doe",
      buttonText: "Learn More",
    },
  ];

  const mentorships = [
    {
      id: 1,
      title: "Adobe Illustrator Sketch Course",
      image: "/adobe.jpg",
      price: "$24.92",
      originalPrice: "$120.42",
      description: "Beginner to Pro in Illustrator. Learn everything in-depth.",
      buttonText: "Enroll Now",
    },
    {
      id: 2,
      title: "Advanced Graphic Design",
      image: "/graphic.jpg",
      price: "$49.99",
      originalPrice: "$150.00",
      description: "Learn advanced techniques in graphic design.",
      buttonText: "Enroll Now",
    },
  ];

  return (
    <>
      <h4>Career Development</h4>
      <div className="CMS-CD-career-development-page">
        {/* Articles Section */}
        <section className="CMS-CD-articles-section">
          <h4>Articles</h4>
          <div className="CMS-CD-articles-container">
            {articles.map((article) => (
              <div key={article.id} className="CMS-CD-article-card">
                <img
                  src={article.image}
                  alt={article.title}
                  className="CMS-CD-article-image"
                />
                <h5>{article.title}</h5>
                <p>{article.author}</p>
                <button className="CMS-CD-learn-more-btn">{article.buttonText}</button>
              </div>
            ))}
          </div>
        </section>

        {/* Mentorship Section */}
        <section className="CMS-CD-mentorship-section">
          <h4>Mentorship</h4>
          <div className="CMS-CD-mentorship-container">
            {mentorships.map((mentorship) => (
              <div key={mentorship.id} className="CMS-CD-mentorship-card">
                <img
                  src={mentorship.image}
                  alt={mentorship.title}
                  className="CMS-CD-mentorship-image"
                />
                <h5>{mentorship.title}</h5>
                <p>{mentorship.description}</p>
                <p className="CMS-CD-price">
                  <span className="CMS-CD-discounted-price">{mentorship.price}</span>
                  <span className="CMS-CD-original-price">
                    {mentorship.originalPrice}
                  </span>
                </p>
                <button className="CMS-CD-enroll-btn">{mentorship.buttonText}</button>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default CareerDevelopment;

import React from "react";
import "./Dashboard.css";

export default function Dashboard() {
  const conferences = [
    {
      name: "AI Conference 2024",
      submissionStatus: "Submitted",
    },
    {
      name: "Web Development Expo",
      submissionStatus: "Pending",
    },
    {
      name: "Data Science Summit",
      submissionStatus: "Approved",
    },
  ];

  return (
    <>
      <h2>Dashboard</h2>
      <div className="CMS_dashboard-head">
        <table className="conference-table">
          <thead>
            <tr>
              <th>Registered Conferences</th>
              <th>Submission Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {conferences.map((conference, index) => (
              <tr key={index}>
                <td>{conference.name}</td>
                <td>{conference.submissionStatus}</td>
                <td>
                  <button className="action-btn view-btn">View</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

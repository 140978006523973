import React, { useState } from "react";
import "./VirutalConference.css";

const VirtualConference = () => {
  const [liveStreams, setLiveStreams] = useState([
    {
      sessionName: "Opening Keynote",
      speakers: "Dr. John Smith",
      streamLink: "https://zoom.us/j/123456789",
      imageUrl:
        "/key.jpg",
    },
    {
      sessionName: "AI in Healthcare",
      speakers: "Prof. Jane Doe",
      streamLink: "https://zoom.us/j/987654321",
      imageUrl:
        "/ai.jpg",
    },
  ]);

  const [recordedSessions, setRecordedSessions] = useState([
    {
      sessionName: "Data Privacy Workshop",
      speakers: "Dr. Emily Davis",
      recordingLink: "https://zoom.us/recording/123456",
      imageUrl:
        "/privacy.jpg",
    },
    {
      sessionName: "Networking and Collaboration",
      speakers: "N/A",
      recordingLink: "https://zoom.us/recording/987654",
      imageUrl:
        "/networking.jpg",
    },
  ]);

  // Form for Q&A submissions
  const [qaForm, setQaForm] = useState({
    name: "",
    question: "",
  });

  const handleQAChange = (e) => {
    setQaForm({
      ...qaForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleQASubmit = (e) => {
    e.preventDefault();
    if (qaForm.name && qaForm.question) {
      alert(`Thank you, ${qaForm.name}! Your question has been submitted.`);
      setQaForm({ name: "", question: "" }); // Reset form after submission
    } else {
      alert("Please fill in all fields.");
    }
  };

  const handleCardClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <h2>Virtual Conference Sessions</h2>
      <div className="CMS-virtual-conference-container">
        {/* Live Stream Links as Cards */}
        <div className="CMS-card-section">
          <h3>Live Stream Links</h3>
          <div className="CMS-card-grid">
            {liveStreams.map((stream, index) => (
              <div
                key={index}
                className="CMS-card"
                onClick={() => handleCardClick(stream.streamLink)}
              >
                <img
                  src={stream.imageUrl}
                  alt={stream.sessionName}
                  className="CMS-card-image"
                />
                <div className="CMS-card-body">
                  <h4>{stream.sessionName}</h4>
                  <p>By {stream.speakers}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Recorded Sessions as Cards */}
        <div className="CMS-card-section">
          <h3>Recorded Sessions</h3>
          <div className="CMS-card-grid">
            {recordedSessions.map((session, index) => (
              <div
                key={index}
                className="CMS-card"
                onClick={() => handleCardClick(session.recordingLink)}
              >
                <img
                  src={session.imageUrl}
                  alt={session.sessionName}
                  className="CMS-card-image"
                />
                <div className="CMS-card-body">
                  <h4>{session.sessionName}</h4>
                  <p>By {session.speakers}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Q&A Section */}
        <div className="CMS-qa-section">
          <h3>Submit a Question for Q&A</h3>
          <form onSubmit={handleQASubmit} className="CMS-qa-form">
            <div className="CMS-form-group">
              <label htmlFor="name">Your Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={qaForm.name}
                onChange={handleQAChange}
                required
              />
            </div>
            <div className="CMS-form-group">
              <label htmlFor="question">Your Question</label>
              <textarea
                name="question"
                id="question"
                value={qaForm.question}
                onChange={handleQAChange}
                required
              />
            </div>
            <button type="submit" className="CMS-button">
              Submit Question
            </button>
          </form>
        </div>

        {/* Chat Feature (simple placeholder for now) */}
        <div className="CMS-chat-section">
          <h3>Live Chat</h3>
          <p>
            Join the live chat to connect with other attendees and speakers.
          </p>
          <a
            href="https://zoom.us/join-chat"
            target="_blank"
            rel="noopener noreferrer"
            className="CMS-button"
          >
            Join Chat
          </a>
        </div>
      </div>
    </>
  );
};

export default VirtualConference;

import React, { useState } from 'react';
import './Profile.css';

const ProfilePage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
    // Handle form submission logic here (e.g., API call to update profile)
    console.log('Profile updated:', formData);
  };

  return (
    <div className="CMS-PROFILE-profile-page">
      <h2 className="CMS-PROFILE-title">Update Profile</h2>
      <form onSubmit={handleSubmit} className="CMS-PROFILE-profile-form">
        
        {/* Name */}
        <div className="CMS-PROFILE-form-group">
          <label htmlFor="name">Full Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        {/* Email */}
        <div className="CMS-PROFILE-form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        {/* Username */}
        <div className="CMS-PROFILE-form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>

        {/* Password */}
        <div className="CMS-PROFILE-form-group">
          <label htmlFor="password">New Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>

        {/* Confirm Password */}
        <div className="CMS-PROFILE-form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>

        {/* Submit Button */}
        <div className="CMS-PROFILE-form-group">
          <button type="submit" className="CMS-PROFILE-submit-btn">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfilePage;

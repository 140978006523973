import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import AuthPage from './pages/authencation/AuthPage'
import Sidebar from './components/sidebar/Sidebar';
import Dashboard from './pages/dashboard/Dashboard';
import "./App.css";
import Header from './components/header/Header';
import ConferenceDetails from './pages/Conference-details/ConferenceDetails';
import CallForPaper from './pages/Call-for-papers/CallForPaper';
import SubmissionManagement from './pages/Submisssion-management/SubmissionManagement';
import Review from './pages/Review-Interface/Review';
import ConferenceSchedule from './pages/Conference-schedule/ConferenceSchedule';
import VirtualConference from './pages/Virtual-Conference/VirtualConference';
import CareerDevelopment from './pages/Career-Development/CareerDevelopment';
import RegistrationPage from './pages/Registration/Registration';
import ProfilePage from './pages/Profile/Profile';

export default function AppRoutes() {
    const location = useLocation();

    useEffect(() => {
      console.log('Current location:', location.pathname);  // Optional: for debugging purposes
    }, [location]);
  
    // Show the sidebar on every route except for `/auth`
    const showSidebar = location.pathname !== '/';
  return (
    <>
        <div className='CMS_main'>
            {showSidebar && <Sidebar/>}
            {showSidebar && <Header/>}
            <div className='CMS_main-content'>
                <Routes>
                  <Route path="/" element={<AuthPage/>} />
                  <Route path="/dashboard" element={<Dashboard/>} />
                  <Route path="/conference-details" element={<ConferenceDetails/>} />
                  <Route path="/call-for-papers" element={<CallForPaper/>} />
                  <Route path="/submission-management" element={<SubmissionManagement/>} />
                  <Route path="/review-interface" element={<Review/>} />
                  <Route path="/conference-schedule" element={<ConferenceSchedule/>} />
                  <Route path="/virtual-conference" element={<VirtualConference/>} />
                  <Route path="/career-development" element={<CareerDevelopment/>} />
                  <Route path="/registration" element={<RegistrationPage/>} />
                  <Route path="/profile-settings" element={<ProfilePage/>} />

                  <Route path="*" element={<h1>404 - Page Not Found</h1>} />
                </Routes>
            </div>
        </div>
    </>
  )
}


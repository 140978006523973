import React, { useState } from "react";
import "./Registration.css";

const RegistrationPage = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    affiliation: "",
    position: "",
    phoneNumber: "",
    mailingAddress: "",
    conferenceTrack: "",
    sessionPreferences: "",
    paperTitle: "",
    abstract: "",
    keywords: "",
    coAuthors: "",
    researchField: "",
    paperFile: null,
    attendeeType: "",
    workshopSelection: "",
    networkingEvents: "",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value, // For file upload
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process form submission logic, e.g., API calls, etc.
    console.log(formData);
    alert("Registration submitted!");
  };

  return (
    <>
      <h2>Conference Registration</h2>
      <div className="CMS-REG-registration-page">
        <form onSubmit={handleSubmit} className="CMS-REG-registration-form">
          {/* Personal Information */}
          <h4>1. Personal Information</h4>
          <div className="CMS-REG-section">
            <div className="CMS-REG-form-group">
              <label htmlFor="fullName">Full Name</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="CMS-REG-form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="CMS-REG-form-group">
              <label htmlFor="affiliation">Affiliation/Institution</label>
              <input
                type="text"
                id="affiliation"
                name="affiliation"
                value={formData.affiliation}
                onChange={handleChange}
                required
              />
            </div>

            <div className="CMS-REG-form-group">
              <label htmlFor="position">
                Position/Title (e.g., Professor, Student)
              </label>
              <input
                type="text"
                id="position"
                name="position"
                value={formData.position}
                onChange={handleChange}
                required
              />
            </div>

            <div className="CMS-REG-form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>

            <div className="CMS-REG-form-group">
              <label htmlFor="mailingAddress">Mailing Address (Optional)</label>
              <input
                type="text"
                id="mailingAddress"
                name="mailingAddress"
                value={formData.mailingAddress}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Conference Information */}
          <h4>2. Conference Information</h4>
          <div className="CMS-REG-section">
            <div className="CMS-REG-form-group">
              <label htmlFor="conferenceTrack">
                Conference Track/Theme (Optional)
              </label>
              <input
                type="text"
                id="conferenceTrack"
                name="conferenceTrack"
                value={formData.conferenceTrack}
                onChange={handleChange}
              />
            </div>

            <div className="CMS-REG-form-group">
              <label htmlFor="sessionPreferences">
                Session Preferences (Optional)
              </label>
              <textarea
                id="sessionPreferences"
                name="sessionPreferences"
                value={formData.sessionPreferences}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Paper Details */}
          <h4>3. Paper Details</h4>
          <div className="CMS-REG-section">
            <div className="CMS-REG-form-group">
              <label htmlFor="paperTitle">Paper Title</label>
              <input
                type="text"
                id="paperTitle"
                name="paperTitle"
                value={formData.paperTitle}
                onChange={handleChange}
                required
              />
            </div>

            <div className="CMS-REG-form-group">
              <label htmlFor="abstract">Abstract</label>
              <textarea
                id="abstract"
                name="abstract"
                value={formData.abstract}
                onChange={handleChange}
                required
              />
            </div>

            <div className="CMS-REG-form-group">
              <label htmlFor="keywords">Keywords</label>
              <input
                type="text"
                id="keywords"
                name="keywords"
                value={formData.keywords}
                onChange={handleChange}
                required
              />
            </div>

            <div className="CMS-REG-form-group">
              <label htmlFor="coAuthors">
                Co-authors' Details (Name, Affiliation, Email)
              </label>
              <textarea
                id="coAuthors"
                name="coAuthors"
                value={formData.coAuthors}
                onChange={handleChange}
              />
            </div>

            <div className="CMS-REG-form-group">
              <label htmlFor="researchField">Research Field/Area</label>
              <input
                type="text"
                id="researchField"
                name="researchField"
                value={formData.researchField}
                onChange={handleChange}
                required
              />
            </div>

            <div className="CMS-REG-form-group">
              <label htmlFor="paperFile">Paper File (PDF/DOCX)</label>
              <input
                type="file"
                id="paperFile"
                name="paperFile"
                accept=".pdf,.docx"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Registration Type */}
          <h4>4. Registration Type</h4>
          <div className="CMS-REG-section">
            <div className="CMS-REG-form-group">
              <label htmlFor="attendeeType">Attendee Type</label>
              <select
                id="attendeeType"
                name="attendeeType"
                value={formData.attendeeType}
                onChange={handleChange}
                required
              >
                <option value="">--Select Type--</option>
                <option value="author">Author</option>
                <option value="non-author">Non-author</option>
                <option value="student">Student</option>
                <option value="industry">Industry</option>
              </select>
            </div>

            <div className="CMS-REG-form-group">
              <label htmlFor="workshopSelection">
                Workshop/Tutorial Selection (Optional)
              </label>
              <input
                type="text"
                id="workshopSelection"
                name="workshopSelection"
                value={formData.workshopSelection}
                onChange={handleChange}
              />
            </div>

            <div className="CMS-REG-form-group">
              <label htmlFor="networkingEvents">
                Networking/Events (Optional)
              </label>
              <input
                type="text"
                id="networkingEvents"
                name="networkingEvents"
                value={formData.networkingEvents}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Submit Button */}
          <div className="CMS-REG-section">
            <div className="CMS-REG-form-group">
              <button type="submit" className="CMS-REG-submit-btn">
                Submit Registration
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default RegistrationPage;

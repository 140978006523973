import React, { useState } from "react";
import "./ConferenceSchedule.css";

const ConferenceSchedule = () => {
  // Initial sessions data
  const [sessions, setSessions] = useState([
    {
      sessionName: "Opening Keynote",
      speakers: "Dr. John Smith",
      time: "09:00 AM - 10:00 AM",
      link: "https://zoom.us/j/123456789",
    },
    {
      sessionName: "Panel Discussion: AI in Healthcare",
      speakers: "Prof. Jane Doe, Dr. Alan Brown",
      time: "10:30 AM - 12:00 PM",
      link: "https://zoom.us/j/987654321",
    },
    {
      sessionName: "Networking Session",
      speakers: "N/A",
      time: "12:30 PM - 01:30 PM",
      link: "",
    },
    {
      sessionName: "Workshop: Data Privacy",
      speakers: "Dr. Emily Davis",
      time: "02:00 PM - 04:00 PM",
      link: "https://zoom.us/j/2468101214",
    },
  ]);

  // Form states
  const [newSession, setNewSession] = useState({
    sessionName: "",
    speakers: "",
    date:"",
    time: "",
    link: "",
  });

  // Handle input change in the form
  const handleChange = (e) => {
    setNewSession({
      ...newSession,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission to add a new session
  const handleSubmit = (e) => {
    e.preventDefault();
    if (newSession.sessionName && newSession.speakers && newSession.time) {
      setSessions([...sessions, newSession]);
      setNewSession({ sessionName: "", speakers: "", time: "", link: "" }); // Clear the form
    } else {
      alert("Please fill in all required fields");
    }
  };

  return (
    <>
      <h2>Conference Schedule</h2>
      <div className="CMS-schedule-schedule-container">
        <table className="CMS-schedule-schedule-table">
          <thead>
            <tr>
              <th>Session Name</th>
              <th>Speakers</th>
              <th>Time</th>
              <th>Join Link</th>
            </tr>
          </thead>
          <tbody>
            {sessions.map((session, index) => (
              <tr key={index}>
                <td>{session.sessionName}</td>
                <td>{session.speakers}</td>
                <td>{session.time}</td>
                <td>
                  {session.link ? (
                    <a
                      href={session.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Join Session
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <h3 className="CMS-schedule-schedule-title">
          Schedule New Conference Session
        </h3>
        <form onSubmit={handleSubmit} className="CMS-schedule-schedule-form">
          <div className="CMS-schedule-form-group">
            <label htmlFor="sessionName">Session Name</label>
            <input
              type="text"
              name="sessionName"
              id="sessionName"
              value={newSession.sessionName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="CMS-schedule-form-group">
            <label htmlFor="speakers">Speakers</label>
            <input
              type="text"
              name="speakers"
              id="speakers"
              value={newSession.speakers}
              onChange={handleChange}
              required
            />
          </div>
          <div className="CMS-schedule-form-group">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              name="date"
              id="date"
              value={newSession.date}
              onChange={handleChange}
              placeholder="e.g. 09:00 AM - 10:00 AM"
              required
              className="schedule-input"
            />
          </div>
          <div className="CMS-schedule-form-group">
            <label htmlFor="time">Time</label>
            <input
              type="text"
              name="time"
              id="time"
              value={newSession.time}
              onChange={handleChange}
              placeholder="e.g. 09:00 AM - 10:00 AM"
              required
            />
          </div>
          <div className="CMS-schedule-form-group">
            <label htmlFor="link">Join Link</label>
            <input
              type="url"
              name="link"
              id="link"
              value={newSession.link}
              onChange={handleChange}
              placeholder="https://"
            />
          </div>
          <button type="submit" className="CMS-schedule-button">
            Add Session
          </button>
        </form>
      </div>
    </>
  );
};

export default ConferenceSchedule;

import React from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faHome, 
    faMicrophone, 
    faFileAlt, 
    faTasks, 
    faEye, 
    faCalendarAlt, 
    faVideo, 
    faBriefcase, 
    faUserGraduate, 
    faUserTie 
  } from '@fortawesome/free-solid-svg-icons'; 

const Sidebar = () => {

    const routes = [
        { path: "/dashboard", pathName: "Dashboard", icon: faHome },
        { path: "/conference-details", pathName: "Conference Details", icon: faMicrophone },
        { path: "/call-for-papers", pathName: "Call for Papers", icon: faFileAlt },
        { path: "/submission-management", pathName: "Submission Management", icon: faTasks },
        { path: "/review-interface", pathName: "Review Interface", icon: faEye },
        { path: "/conference-schedule", pathName: "Conference Schedule", icon: faCalendarAlt },
        { path: "/virtual-conference", pathName: "Virtual Conference", icon: faVideo },
        { path: "/career-development", pathName: "Career Development", icon: faBriefcase },
        { path: "/registration", pathName: "Registration", icon: faUserTie }
      ];

  return (
    <div className="sidebar">
      <div className="logo">
        <img src="/CMS logo.png" alt="Logo" />
      </div>

      <ul className="menu">
        {routes.map((route, index) => (
          <li key={index}>
            <NavLink 
              to={route.path} 
              className={({ isActive }) => isActive ? 'active' : ''}
            >
              <FontAwesomeIcon icon={route.icon} className="icon" /> {route.pathName}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;

import React from 'react';
import './ConferenceDetails.css'; // Add the CSS file for styling

const ConferenceDetails = () => {
  return (
    <>
      <h2>Conference Details</h2>
    <div className="conference-details-container">

      <div className="conference-section">
        <label className="conference-label">Conference Name</label>
        <p className="conference-text">Network And Engage With Your Audience</p>
      </div>

      <div className="conference-section">
        <label className="conference-label">Conference description</label>
        <p className="conference-description">
          <ul>
            <li>Previews tell listeners what's coming next or how you're going to develop a point. For instance, in a discussion of why discrepancies exist between cars EPA gas mileage ratings and actual gas mileage, you might say “First Im going to explain how the EPA arrives at its numbers. Then I'll explain how the Consumers Union conducts its tests</li>
            <li>Summaries remind listeners of what's important in what was just covered. A summary is especially useful in reframing or refocusing the discussion after a string of supporting details or after any fairly lengthy discussion of a point</li>
          </ul>
        </p>
      </div>

      <div className="schedule-call-container">
        <div className="schedule-section">
          <label className="conference-label">Schedule</label>
          <input type="date" className="schedule-input" />
        </div>
        <div className="call-section">
          <label className="conference-label">Call for papers</label>
          <button className="send-notification-btn">Send Notification</button>
        </div>
      </div>

      {/* <div className="button-container">
        <button className="register-btn">Registration</button>
        <button className="save-btn">Save</button>
      </div> */}
    </div>
    </>
  );
};

export default ConferenceDetails;
